const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default {
  steps: ({ tour, $vue }) => [
    {
      attachTo: { element: "#nav-sell-btn", on: "right" },
      advanceOn: { selector: "#nav-sell-btn", event: "click" },
      cancelIcon: { enabled: true },
      beforeShowPromise: async () => {
        $vue.$store.commit("setVerticalSidebarDrawer", true);
        await sleep(500);
      },
      showOn: () => $vue.$route.path != "/admin/organization/sell",
      title: "Clique no botão vender.",
    },
    {
      attachTo: { element: "#form-sell-party", on: "bottom" },
      cancelIcon: { enabled: true },
      beforeShowPromise: () => {
        $vue.$store.commit("setVerticalSidebarDrawer", false);
      },
      when: {
        show: function () {
          $vue.$root.$once("sell-step:ticket", tour.next);
        },
      },
      title: "Escolha o evento que deseja vender.",
      scopeUris: ["/admin/organization/sell"],
    },
    {
      attachTo: { element: "#form-sell-ticket", on: "bottom" },
      cancelIcon: { enabled: true },
      beforeShowPromise: () => {
        $vue.$store.commit("setVerticalSidebarDrawer", false);
      },
      when: {
        show: function () {
          $vue.$root.$once("sell-step:user", tour.next);
        },
      },
      title: "Selecione o lote que deseja vender.",
      scopeUris: ["/admin/organization/sell"],
    },
    {
      attachTo: { element: "#form-sell-user", on: "bottom" },
      cancelIcon: { enabled: true },
      beforeShowPromise: () => {
        $vue.$store.commit("setVerticalSidebarDrawer", false);
      },
      when: {
        show: function () {
          $vue.$root.$once("sell-step:finish", tour.next);
        },
      },
      title: "Informe o Whatsapp do cliente.",
      scopeUris: ["/admin/organization/sell"],
    },
  ],
};
